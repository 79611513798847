import { Vue } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";

export default abstract class ConfirmCosts extends Vue {
  protected skeletonRows = 10;
  protected skeletonHeight = `1.2rem`;
  protected pageNumber = 1;
  protected pageSize = 10;
  protected pending = false;
  protected filter: string | null = null;
  protected filterValue: string | null = null;
  protected filterYear = new Date().getFullYear();
  protected filterMonth: number | null = null;
  protected totalPages = 0;
  protected get qs(): Array<[string, string | number | null]> {
    return [["dt", new Date().getTime()]];
  }

  protected get path() {
    return this.$route.path;
  }

  private filterGen(page: number) {
    return [["page", page], ...this.qs, ["dt", new Date().getTime()]]
      .filter(([, value]) => value)
      .map(([key, value]) => [key, value]);
  }

  protected linkGen(page: number) {
    const filters = this.filterGen(page)
      .map(([key, value]) => [key, value].join("="))
      .join("&");
    return `?${filters}`;
  }

  protected search(page: number) {
    const filter = this.filterGen(page);
    const queries = filter.reduce((acc, [key, value]) => {
      if (key && value) acc[key] = value.toString();
      return acc;
    }, {} as Dictionary<string>);
    return this.$router.push({ query: queries });
  }

  protected get years() {
    const min = 2021,
      current = new Date().getFullYear();
    return Array.from({ length: current - min + 1 }, (v, k) => k + min).map(
      (year) => ({ text: year, value: year })
    );
  }

  protected get isAdmin() {
    return this.$store.getters.isAdmin;
  }

  protected get months() {
    const date = new Date();
    const month: Array<{
      text: string | number;
      value: string | number | null;
    }> = Array.from(
      {
        length:
          this.filterYear === date.getFullYear() ? date.getMonth() + 1 : 12,
      },
      (v, k) => k + 1
    ).map((month) => ({ text: month, value: month }));
    return [{ text: "All", value: null }, ...month];
  }
}
