
import { Component } from 'vue-property-decorator'
import moment from 'moment';
import ConfirmCosts from '@/pages/project/confirm-costs/class/confirm.costs';

interface Docs { _id: string, surveyId: string; surveyNo: number; date: string; title: string; LOI: number; total: number; managementFee: number; currency: string; groupId: string;  }

interface summary {
  COMPLETE: number;
  TERMINATE: number;
  QUOTA_FULL: number;
}

@Component({})
export default class ConfirmCostsList extends ConfirmCosts{
  private downloading = false;
  private docs: Docs[] = [];
  constructor() {
    super();
    console.log('constructor');
  }

  protected get qs (): Array<[ string, string | number | null ]> {
    return [
      [ 'filter', this.filter ],
      [ 'filterValue', this.filterValue ],
      [ 'filterYear', this.filterYear ],
      [ 'filterMonth', this.filterMonth ],
    ];
  }

  created () {
    console.log('create');

    this.getFilter();
    this.load(this.pageNumber);
  }

  private getFilter () {
    const { page, filterYear,filter, filterValue, filterMonth } = this.$route.query;
    if (page && !isNaN(+page)) this.pageNumber = +page;
    if (filterYear && !isNaN(+filterYear)) this.filterYear = +filterYear;
    if (filterMonth && !isNaN(+filterMonth)) this.filterMonth = +filterMonth;
    if (filter && filterValue && typeof filter === 'string' && typeof filterValue === 'string') {
      this.filter = filter;
      this.filterValue = filterValue;
    }
  }

  private dateConvert(date: string) {
    return moment(date).format('Y/MM');
  }

  private async load (page: number) {
    try {
      if (page) this.pageNumber = page;
      this.pending = true;
      const params = {
        page: this.pageNumber,
        filter: this.filter,
        filterValue: this.filterValue,
        filterYear: this.filterYear,
        filterMonth: this.filterMonth,
      };
      const response  = await this.axios.get<{ totalDocs: number; totalPages: number; list: Docs[]  }>('/project/confirm-costs/project', {
        params,
      });
      const { data, result, message } = response;
      if (result) {
        const { list, totalDocs, totalPages } = data;
        this.docs = list;
        this.totalPages = totalPages;
      } else if(message) {
        this.validationMessages(message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.pending = false;
    }
  }

  private get filterOptions () {
    return [
      { text: this.$t('PROJECT.CONFIRM_COSTS.SELECT'), value: null, },
      { text: this.$t('PROJECT.CONFIRM_COSTS.PROJECT_NAME'), value: 'title' }
    ];
  }

  private async download () {
    try {
      this.downloading = true;
      const params = {
        page: this.pageNumber,
        filter: this.filter,
        filterValue: this.filterValue,
        filterYear: this.filterYear,
        filterMonth: this.filterMonth,
      };
      const { result, data, message, headers } = await this.axios.get(`/project/confirm-costs/project/download`, {
        params,
        onDownloadProgress: (progressEvent) => {
          const { loaded } = progressEvent;
          console.log(loaded);
        },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = headers['content-disposition'];
      const match = contentDisposition.match(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i);
      if (match && match[2]) {
        link.setAttribute('download', match[2]);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    } catch (e) {
      console.log(e);
    } finally {
      this.downloading = false;
    }
  }

}
